<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text"> Mes actions </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <v-row class="pb-4" align="center" no-gutters>
            <v-col cols="12" md="2">
                <v-text-field v-model="quickSearch" @keydown.enter="fetchActions(true)" label="Recherche..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchActions(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
                <v-btn class="mx-4" @click="displayAdvanceSearch = !displayAdvanceSearch" color="primary" outlined depressed small>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="grey lighten-2" v-on="on">
                            {{ actionsCount }}
                        </v-btn>
                    </template>
                    Nombre total d'actions
                </v-tooltip>
            </v-col>

            <v-col cols="12" md="7" class="text-right">
                <v-select class="sort-pagination d-inline-flex" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="actionsSortItems" background-color="white" @change="fetchActions(true)" hide-details outlined dense>
                    <template v-slot:append-outer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                    <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                        fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-if="by === 'asc'">
                                Croissant
                            </template>
                            <template v-if="by === 'desc'">
                                Décroissant
                            </template>
                        </v-tooltip>

                        <v-pagination v-model="page" @input="fetchActions(true, false)" :length="totalPages" :total-visible="7" class="pagination" color="primary" />
                    </template>
                </v-select>
            </v-col>
        </v-row>

        <!-- Advance search -->
        <v-expand-transition>
            <v-card v-show="displayAdvanceSearch" elevation="0">
                <v-sheet color="grey lighten-3" class="pa-4 mb-4">
                    <span class="caption grey--text text--darken-1"> Action </span>

                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary" class="mr-4" dark depressed small @click="fetchActions(true)">
                                <v-icon left x-small> fas fa-search </v-icon>
                                Rechercher
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card>
        </v-expand-transition>

        <!-- Filters and actions list -->
        <v-data-table class="elevation-2" :headers="headers" :items="actions" item-key="id" hide-default-footer :items-per-page="-1" :show-expand="false" :single-expand="false">
            <template v-slot:item.date="{ item }">
              {{ item.created | toDatetime() }}
            </template>
            
            <template v-slot:item.type="{ item }">
              {{ item.type }}
            </template>

            <template v-slot:item.partner="{ item }">
              {{ item.partner.name }} <br>
              {{ item.partner.mobile }} {{ item.partner.phone }} {{ item.partner.email }}
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name: 'History',

    data: () => ({
        displayAdvanceSearch: false,

        quickSearch: null,

        typesFilter: [],
        partnersFilter: [],

        page: 1,
        limit: 10,
        order: 'created',
        by: 'desc',

        actions: [],
        actionsCount: 0,
        totalPages: 0,
        disableNavigation: false,

        advanceSearchDataFetched: false,

        headers: [
            {
                text: 'Date',
                value: 'date',
                sortable: false
            },
            {
                text: 'Type',
                value: 'type',
                sortable: false
            },
            {
                text: 'Partenaire',
                value: 'partner',
                sortable: false
            }
        ],
        actionsSortItems: [
            { text: 'Date de création', value: 'created' },
            { text: 'Type', value: 'type' }
        ]
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy: `${this.order}:${this.by}`
            };

            if (Array.isArray(this.typesFilter) && this.typesFilter.length > 0) {
                query.types = this.typesFilter.join(',');
            }

            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }

            if (Array.isArray(this.typesFilter) && this.typesFilter.length > 0) {
                query.types = this.typesFilter.join(',');
            }

            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }

            if (this.order !== 'created') {
                query.order = this.order;
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.limit = parseInt(query.limit) || 10;

            this.quickSearch = query.quickSearch || null;

            this.typesFilter = query.types ? query.types.split(',').map((t) => parseInt(t)) : [];

            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchActions(push, resetPage = true) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { actions, count, totalPages, err } = await this.repos.actions.getActions(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.actions = actions;
                    this.actionsCount = count;
                    this.totalPages = totalPages;
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchActions(true);
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                this.parseQuery(to.query);
                this.fetchActions(false);
            }
        },

        displayAdvanceSearch() {
            if (this.displayAdvanceSearch && !this.advanceSearchDataFetched) {
                this.advanceSearchDataFetched = true;
            }
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.fetchActions(false);
    }
};
</script>

<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    width: initial;
    max-width: initial;

    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>